const section = document.querySelector('.tlw__home__expose');
const x = window.matchMedia('(min-width: 1024px)');

window.addEventListener('load', function () {
	if (document.body.contains(section)) {
		const panels = section.querySelectorAll('.list li');
		const heightSection = section.clientHeight;

		panels.forEach(function (panel, i) {
			const head = panel.querySelector('.list-header');
			const child = head.nextElementSibling;
			const images = child.querySelectorAll('.item .item__image');

			function hoverPanel(x) {
				if (x.matches) {
					// panels[0].classList.add("active");
					images.forEach(function (item) {
						item.style.height = heightSection + 'px';
					});
					panel.addEventListener(
						'mouseover',
						function () {
							panels[0].classList.remove('active');
							if (i !== 0) {
								panel.classList.add('active');
							} else {
								panels[0].classList.add('active');
							}
						},
						false,
					);
					panel.addEventListener(
						'mouseout',
						function () {
							panels[0].classList.add('active');
							if (i !== 0) {
								panel.classList.remove('active');
							}
						},
						false,
					);
				}
			}
			hoverPanel(x);
			x.addListener(hoverPanel);
			head.addEventListener('click', () => {
				for (const otherPanel of panels) {
					if (otherPanel !== panel) {
						otherPanel.classList.remove('active');
					}
				}
				panel.classList.toggle('active');
			});
		});
	}
});
